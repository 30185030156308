import React from 'react';
import { AppContext } from './src/context/AppContext';
import Layout from './src/components/Layout';

import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  release: "dust2-3.0.0",
  integrations: [
    new Sentry.BrowserTracing(),  // Esto debería estar instanciado con `new`
    new Sentry.Replay(), // También debe estar instanciado
  ],
  tracesSampleRate: 1.0, // Puedes reducirlo en producción
  replaysSessionSampleRate: 0.1, // Muestra sesiones al 10%, ajústalo según tu necesidad
  replaysOnErrorSampleRate: 1.0, // Siempre muestra sesiones cuando hay errores
  tracePropagationTargets: ["localhost", /^https:\/\/dust2\.gg\//],
});


export const wrapRootElement = ({ element }) => {
  return (
    <AppContext>
        <Layout>
          {element}
        </Layout>
    </AppContext>
  );
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Verifica si la URL tiene un hash (indicando un enlace interno)
  if (location.hash) {
    const id = location.hash.substring(1); // extrae el id sin el '#'
    const element = document.getElementById("Main__Header");
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 100, // ajusta este valor al tamaño de tu header
        behavior: 'smooth' // opcional: añade un efecto de transición suave
      });
    }
    // retorna falso para indicar que el scroll se maneja manualmente
    return false;
  }

  // En otros casos, regresa a la posición guardada o al top de la página
  const savedPosition = getSavedScrollPosition(location);
  if (savedPosition) {
    return savedPosition;
  }

  return true;
};